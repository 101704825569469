import { Router } from "next/router";
import React from "react";
import { useRouter } from "next/router";
interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
  flavor?: "word" | "icon";
  alt?: string;
}

export default function Logo(props: PropTypes) {
  const flavor = props.flavor || "word";
  const fill = props.fill || "#000000";
  const title = props.alt || "Scythe Robotics";
  const router = useRouter()

  if (flavor === "word") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 702.6 221.71"
        width={props.width}
        height={props.height}
        fill={fill}
      >
        <title>{title}</title>
        <g id="logo">
          <polygon points="545.43 54.99 517.94 54.99 510.27 97.11 477.51 97.11 485.18 54.99 457.69 54.99 437.34 166.72 464.84 166.72 472.51 124.61 505.26 124.61 497.59 166.72 525.09 166.72 545.43 54.99" />
          <path d="M147.07,149l4.81-26.41a21.6,21.6,0,0,0-21.25-25.47H106.07A4.31,4.31,0,0,1,101.82,92l1.09-6a4.32,4.32,0,0,1,4.25-3.55h52L164.26,55H99.09A21.61,21.61,0,0,0,77.83,72.72L73,99.14A21.59,21.59,0,0,0,94.28,124.6h24.56a4.33,4.33,0,0,1,4.25,5.1l-1.09,6a4.33,4.33,0,0,1-4.25,3.55h-52l-5,27.49h65.12A21.59,21.59,0,0,0,147.07,149Z" />
          <path d="M239.5,139.23H199.66a4.32,4.32,0,0,1-4.25-5.09L204.17,86a4.34,4.34,0,0,1,4.25-3.55h47.32L243.17,55H200.36A21.61,21.61,0,0,0,179.1,72.72l-12.48,68.53a21.6,21.6,0,0,0,21.25,25.47h46.62Z" />
          <polygon points="410.22 82.48 434.63 82.48 439.63 54.99 378.79 54.99 356.24 82.48 382.73 82.48 367.39 166.72 394.88 166.72 410.22 82.48" />
          <path d="M32.86,17.73.36,196.25a21.59,21.59,0,0,0,21.25,25.46H648.55a.73.73,0,0,0,.57-.26l21.4-26a.73.73,0,0,0-.57-1.19H34.26a5,5,0,0,1-5-5.94L57.82,31.63a5,5,0,0,1,5-4.14H668.34a5,5,0,0,1,4.95,5.95l-18.5,101.65a5,5,0,0,1-5,4.14H576.76a.73.73,0,0,1-.72-.86l2.36-13.16a.73.73,0,0,1,.72-.61h49.45a.73.73,0,0,0,.72-.59L634,98a.73.73,0,0,0-.72-.86h-49a.73.73,0,0,1-.72-.86l2.36-13.16a.73.73,0,0,1,.72-.61h49.59a.72.72,0,0,0,.72-.59l4.74-26A.73.73,0,0,0,641,55H564.08a.74.74,0,0,0-.72.6L543.28,165.86a.72.72,0,0,0,.71.86H658.5A21.59,21.59,0,0,0,679.75,149L702.24,25.47A21.6,21.6,0,0,0,681,0H54.11A21.59,21.59,0,0,0,32.86,17.73Z" />
          <polygon points="314.56 111.15 360.74 54.99 327.98 54.99 306.19 81.5 293.98 54.99 261.22 54.99 287.08 111.15 276.95 166.72 304.45 166.72 314.56 111.15" />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        width={props.width}
        height={props.height}
        fill={fill}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <path d="M28.9255 3.7425e-07H7.69686C6.97727 0.000315675 6.28059 0.253032 5.72815 0.714137C5.1757 1.17524 4.8025 1.81552 4.67354 2.52346L0.0497988 27.9228C-0.0309264 28.3657 -0.0133377 28.821 0.101321 29.2564C0.215979 29.6918 0.424907 30.0966 0.713324 30.4424C1.00174 30.7881 1.3626 31.0662 1.77038 31.257C2.17816 31.4479 2.62289 31.5468 3.07312 31.5468H24.3145L27.531 27.6345H4.87377C4.76801 27.6364 4.66315 27.6147 4.56686 27.5709C4.47057 27.5271 4.38528 27.4624 4.31723 27.3814C4.24917 27.3004 4.20007 27.2052 4.17352 27.1029C4.14696 27.0005 4.14362 26.8934 4.16374 26.7896L8.22087 4.49451C8.25124 4.32869 8.33906 4.17887 8.46891 4.07136C8.59876 3.96385 8.76233 3.90552 8.9309 3.90661H27.122C27.2277 3.90468 27.3326 3.9264 27.4289 3.9702C27.5252 4.01399 27.6105 4.07874 27.6785 4.15973C27.7466 4.24071 27.7957 4.33588 27.8222 4.43827C27.8488 4.54066 27.8521 4.6477 27.832 4.75155L24.3784 23.7222H28.2907L31.9502 3.62401C32.0309 3.18096 32.0133 2.72558 31.8986 2.29009C31.7839 1.8546 31.5748 1.44965 31.2862 1.1039C30.9977 0.758158 30.6366 0.480064 30.2287 0.289303C29.8207 0.0985424 29.3758 -0.000221871 28.9255 3.7425e-07Z" />
        <path d="M14.6411 12.2406C14.6667 12.099 14.7414 11.9709 14.852 11.8787C14.9626 11.7865 15.102 11.7362 15.246 11.7365H22.6502L23.3716 7.82422H14.0972C13.3779 7.82453 12.6816 8.07709 12.1294 8.53794C11.5773 8.99878 11.2042 9.6387 11.0753 10.3463L10.3894 14.1052C10.3086 14.5481 10.3262 15.0034 10.4409 15.4388C10.5556 15.8741 10.7645 16.279 11.0529 16.6247C11.3413 16.9705 11.7022 17.2486 12.11 17.4394C12.5177 17.6303 12.9625 17.7292 13.4127 17.7292H16.9075C16.9975 17.7294 17.0865 17.7494 17.168 17.7877C17.2495 17.826 17.3216 17.8817 17.3793 17.9509C17.437 18.02 17.4788 18.101 17.5018 18.1881C17.5248 18.2752 17.5284 18.3662 17.5124 18.4548L17.3576 19.3069C17.3318 19.4486 17.2571 19.5767 17.1466 19.669C17.0361 19.7614 16.8967 19.8121 16.7527 19.8124H9.34846L8.63843 23.7233H17.9044C18.6238 23.7233 19.3205 23.4709 19.873 23.01C20.4254 22.5492 20.7987 21.9091 20.9277 21.2013L21.6122 17.4423C21.6932 16.9991 21.6757 16.5434 21.561 16.1076C21.4463 15.6719 21.2371 15.2667 20.9484 14.9207C20.6596 14.5748 20.2983 14.2966 19.8901 14.1058C19.4818 13.9151 19.0366 13.8164 18.586 13.8169H15.0912C15.0009 13.8176 14.9116 13.7983 14.8296 13.7604C14.7476 13.7225 14.675 13.667 14.6169 13.5978C14.5588 13.5286 14.5168 13.4475 14.4937 13.3602C14.4706 13.2728 14.4671 13.1815 14.4834 13.0927L14.6411 12.2406Z" />
      </svg>
    );
  }
}

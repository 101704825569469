import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";

import SubscribeModal from "@components/SubscribeModal";

interface PropTypes {
  thick?: boolean;
  className?: string;
  track?: () => void;
}

export default function SubscribeButton(props: PropTypes) {
  // Subscribe Modal State
  let [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const openSubscribeModal = (evt: any) => {
    evt.preventDefault();
    if (props.track !== undefined) {
      props.track();
    }
    setShowSubscribeModal(true);
  };
  const closeSubscribeModal = (evt: any) => {
    evt.preventDefault();
    setShowSubscribeModal(false);
  };

  return (
    <>
      <a onClick={openSubscribeModal} href="#" className={props.className}>
        <span className={`unskew ${props.thick ? "thick" : ""}`}>
          Subscribe
        </span>
      </a>
      <SubscribeModal
        open={openSubscribeModal}
        close={closeSubscribeModal}
        isOpen={showSubscribeModal}
      />
    </>
  );
}

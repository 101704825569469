import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import Logo from "@components/svg/Logo";

import * as ga from "@utils/ga";

interface PropTypes {
  fillBackground?: boolean;
  blurBackground?: boolean;
}

export default function HeaderNavigation({ fillBackground, blurBackground  }: PropTypes) {
  const router = useRouter();
  const [backgroundFill, setBackgroundFill] = useState(
    fillBackground ? true : false
  );

  useEffect(() => {
    if (fillBackground !== undefined) {
      if (fillBackground) {
        setBackgroundFill(true);
      }
    } else {
      const handleScroll = () => {
        if (window.scrollY > 10) {
          setBackgroundFill(true);
        } else {
          setBackgroundFill(false);
        }
      };
      document.addEventListener("scroll", handleScroll, { passive: true });
      return () => document.removeEventListener("scroll", handleScroll);
    }
  });

  const isActive = (path: string) => {
    if (router.pathname === path) {
      return "active";
    }
    return "";
  };

  const handleJoinTeamClick = (e: any) => {
    ga.event("click.join-team-nav", {
      event_label: "Join Our Team Click in Header Navigation",
      event_category: "join_click",
    });
  };

  return (
    <nav
      style={{padding: '1rem'}}
      className={`navbar navbar-dark navbar-expand-md fixed-top
      ${ backgroundFill ? "active" : "" }
      ${ blurBackground ? "blur" : "" }
      `}
    >
      <Link href="/">
        <div>
          <a className="navbar-brand d-flex" style ={{position: 'absolute'}}/>
          <Logo height={30} fill="#ffffff" />
        </div>
      </Link>

      <ul className="navbar-nav navbar-right">
        <li className="nav-item d-none d-sm-flex">
          <Link href="/mower" >
            <a className={`nav-link ${isActive("/mower")}`}>Mower</a>
          </Link>
        </li>
        <li className="nav-item">
          <Link href="/tech">
           <a className={`nav-link ${isActive("/tech")}`}>Technology</a>
          </Link>
        </li>
        <li className="nav-item">
          <Link href="/about">
           <a className={`nav-link ${isActive("/about")}`}>About</a>
          </Link>
        </li>
        <li className="nav-item">
          <Link href="https://blog.scytherobotics.com/">
           <a className={`nav-link`}>Blog</a>
          </Link>
        </li>
        <li className="nav-item">
          <a
            onClick={handleJoinTeamClick}
            className="btn btn-skew text-uppercase d-none d-sm-inline-block d-lg-inline-block px-4 ml-md-2"
            style={{backgroundColor: '#00b140'}}
            href="https://boards.greenhouse.io/scytherobotics"
            target="_blank"
          >
            <span className="unskew">Join Our Team</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";

import ContactModal from "@components/ContactModal";

interface PropTypes {
  arrow?: boolean;
  thick?: boolean;
  className?: string;
  track?: () => void;
  buttonText?: string;
  purpose?: string;
}

export default function ContactButton(props: PropTypes) {
  // Contact Modal State
  let [showContactModal, setShowContactModal] = useState(false);
  const openContactModal = (evt: any) => {
    evt.preventDefault();

    if (props.track !== undefined) {
      props.track();
    }

    setShowContactModal(true);
  };

  const closeContactModal = (evt: any) => {
    evt.preventDefault();
    setShowContactModal(false);
  };

  return (
    <>
      <a onClick={openContactModal} href="#" className={props.className}>
        <span className={`unskew ${props.thick ? "thick" : ""}`}>
          {props.buttonText === undefined ? "Contact Us" : props.buttonText}
          {props.arrow ? "→" : ""}
        </span>
      </a>
      <ContactModal
        open={openContactModal}
        close={closeContactModal}
        isOpen={showContactModal}
        purpose={props.purpose}
      />
    </>
  );
}

import React from "react";
import Link from "next/link";

import Logo from "@components/svg/Logo";
import ContactButton from "@components/ContactButton";
import SubscribeButton from "@components/SubscribeButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import * as ga from "@utils/ga";

const trackPressKit = (e: any) => {
  ga.event("click.presskit", {
    event_label: "Press Kit Download",
    event_category: "presskit",
  });
};

const Footer: React.FC = () => {
  return (
    <footer className="container pt-5 pb-3">
      <div className="" style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{minWidth: '20rem'}}>
          <Link href="/">
            <>
              <a className="navbar-brand d-flex"/>
              <Logo flavor="icon" height={64} fill="#00b140" />
            </>
          </Link>
          <small className="d-block mb-3 text-muted">
            Copyright &copy; {(new Date()).getFullYear()} Scythe&nbsp;Robotics.<br />
            All rights reserved.
            <br />
          </small>
          <br />
        </div>
        <div className="col-6 col-md" style={{maxWidth: '15rem'}}>
          <h5 className="text-primary">Quick Links</h5>
          <ul className="list-unstyled text-small">
            <li>
              <Link href="/mower">
              <a className="text-muted">Mower</a>
              </Link>
            </li>
            <li>
              <Link href="/tech" >
               <a className="text-muted">Technology</a>
              </Link>
            </li>
            <li>
              <Link href="/about" >
               <a className="text-muted">About</a>
              </Link>
            </li>
            <li>
              <Link href="https://blog.scytherobotics.com">
               <a className="text-muted">Blog</a>
              </Link>
            </li>
            <li>
              <Link href="/events">
              <a className="text-muted">Events</a>
              </Link>
            </li>
            <li>
              <Link
                href="https://drive.google.com/drive/folders/1NgzMR9nbVWbq5RzsWTc0V4NRDRxrhpH2?usp=share_link">
                  <a className="text-muted" onClick={trackPressKit}>Press Kit</a>                
              </Link>
            </li>
            <li>
              <Link href="/privacy">
                <a className="text-muted">Privacy Policy</a>
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <div className="row">
            <div className="col-12">
            <h5 style = {{color: '#00b140'}}>Connect</h5>
              <a
                className="text-muted mx-1"
                href="https://www.linkedin.com/company/scythe-robotics"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
              </a>
              <a
                className="text-muted mx-1"
                href="https://twitter.com/ScytheRobotics"
                target="_blank"
                rel="noopener noreferrer"
                title="Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </a>
              <a
                className="text-muted mx-1"
                href="https://www.instagram.com/scytherobotics/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a
                className="text-muted mx-1"
                href="https://www.facebook.com/scytherobotics/"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook"
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a
                className="text-muted mx-1"
                href="https://www.youtube.com/c/ScytheRobotics"
                title="YouTube"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="lg" />
              </a>
            </div>
            <div className="col-12 mt-3">
              <ul className="list-unstyled text-small">
                <li>
                  <SubscribeButton
                    className="text-muted"
                    track={() => {
                      ga.event("click.subscribe-footer", {
                        event_label: "Subscribe Footer",
                        event_category: "subscribe",
                      });
                    }}
                  />
                </li>
                <li>
                  <ContactButton
                    arrow={false}
                    className=" text-muted"
                    track={() => {
                      ga.event("click.contact-footer", {
                        event_label: "Contact Footer",
                        event_category: "contact",
                      });
                    }}
                  />
                </li>
                <li>
                  <a
                    className="text-muted"
                    href="https://boards.greenhouse.io/scytherobotics"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Careers"
                  >
                    Careers
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

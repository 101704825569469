import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";
import Image from "next/image";

interface PropTypes {
  open: (evt: any) => void;
  close: (evt: any) => void;
  isOpen: boolean;
  purpose?: string;
}

export default function ContactModal({
  open,
  close,
  isOpen,
  purpose,
}: PropTypes) {
  let [success, setSuccess] = useState(false);
  let [submitting, setSubmitting] = useState(false);

  const submitContactform = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (submitting) {
        return;
    }
    setSubmitting(true);

    const target = event.target as typeof event.target & {
      fname: { value: string };
      lname: { value: string };
      company: { value: string };
      purpose: { value: string };
      email: { value: string };
      message: { value: string };
    };

    const purposeString =
      purpose === undefined ? target.purpose.value : purpose;

    const res = await fetch("/api/contact", {
      body: JSON.stringify({
        fname: target.fname.value,
        lname: target.lname.value,
        company: target.company.value,
        purpose: purposeString,
        email: target.email.value,
        message: target.message.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    const result = await res.json();
    if (result.status === "success") {
      setSuccess(true);
      setSubmitting(false);
    }
  };
  Modal.setAppElement("#__next");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="scythe-modal"
      contentLabel="Contact Us"
    >
      <div className="scythe-modal-wrapper">
        <div className="scythe-modal-left"></div>

        <div className="scythe-modal-right">
          <div className="scythe-modal-header">
            <h2>Contact Us</h2>

            <a className="btn-close" href="#" onClick={(e) => close(e)}>
              <FontAwesomeIcon icon={faTimes} size="lg" color="#000000" />
            </a>
          </div>
          <div className="scythe-modal-content">
            {success ? (
              <div>
                <h4>Thank you</h4>
                <p>We'll be in touch soon!</p>
              </div>
            ) : (
              <form onSubmit={submitContactform}>
                <div className="form-group row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      id="fName"
                      placeholder="First Name"
                      name="fname"
                      autoFocus
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      id="lName"
                      placeholder="Last Name"
                      name="lname"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="company"
                    placeholder="Company"
                    name="company"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="contactEmail"
                    name="email"
                    placeholder="Email Address"
                    required
                  />
                </div>

                {purpose === undefined ? (
                  <div className="form-group">
                    <select
                      className="custom-select mr-sm-2"
                      id="inquiryPurpose"
                      name="purpose"
                      required
                    >
                      <option value="">Reason for Contact</option>
                      <option value="want">I Want Scythe</option>
                      <option value="partnership">Partnership Inquiry</option>
                      <option value="press">Press Inquiry</option>
                      <option value="career">Careers</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="contactMessage"
                    rows={10}
                    name="message"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-lg btn-primary btn-skew float-right"
                  disabled={submitting}
                >
                  <span className="unskew">Send</span>
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

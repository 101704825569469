import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";

interface PropTypes {
  open: (evt: any) => void;
  close: (evt: any) => void;
  isOpen: boolean;
}

export default function SubscribeModal(props: PropTypes) {
  let { open, close, isOpen } = props;
  let [success, setSuccess] = useState(false);

  const submitSubscribeform = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      email: { value: string };
    };

    const res = await fetch("/api/subscribe", {
      body: JSON.stringify({
        email: target.email.value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    const result = await res.json();
    if (result.status === "success") {
      setSuccess(true);
    }
  };
  Modal.setAppElement("#__next");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="scythe-modal"
      contentLabel="Contact Us"
    >
      <div className="scythe-modal-wrapper">
        <div className="scythe-modal-left"></div>

        <div className="scythe-modal-right">
          <div className="scythe-modal-header">
            <h2>Subscribe to Scythe's Newsletter</h2>
            <a className="btn-close" href="#" onClick={(e) => close(e)}>
              <FontAwesomeIcon icon={faTimes} size="lg" color="#000000" />
            </a>
          </div>
          <div className="scythe-modal-content">
            {success ? (
              <div>
                <h4>Thanks for Subscribing</h4>
                <p>
                  You'll be the first to hear about exciting new developments.
                </p>
              </div>
            ) : (
              <>
                <p className="scythe-modal-description">
                  Be the first to hear about exciting new developments at
                  Scythe!
                </p>

                <form onSubmit={submitSubscribeform}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="SubscribeEmail"
                      name="email"
                      placeholder="Email Address"
                      autoFocus
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-lg btn-primary btn-skew float-right"
                  >
                    <span className="unskew">Subscribe</span>
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
